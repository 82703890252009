<template>
  <div class="trend-wrap">
    <faceForm @getFormParams="getFormParams"></faceForm>
    <section class="section charts-wrap">
      <div class="title">{{chart.title}}</div>
      <div class="chart-wrap">
        <div class="tab-wrap">
          <tab :tabs="tabs" @handleTabClick="handleTabClick"></tab>
          <el-button-group class="views-group">
            <el-button size="mini" :type="view === 'VeLine' ? 'primary' : 'default'" icon="el-icon-data-line" @click="view = 'VeLine'"></el-button>
            <el-button size="mini" :type="view === 'VeHistogram' ? 'primary' : 'default'" icon="el-icon-s-data" @click="view = 'VeHistogram'"></el-button>
          </el-button-group>
        </div>
        <component :is="view" width="100%" height="300px" :data="chart.data" :dataZoom="chart.dataZoom"></component>
      </div>
    </section>
  </div>
</template>

<script>
  import faceForm from "@/views/face/form"
  import VeLine from 'v-charts/lib/line'
  import VeHistogram from 'v-charts/lib/histogram'
  import "echarts/lib/component/dataZoom"
  import Api from "@/views/face/api"
  import tab from "@/components/tab"

  export default {
    name: "trend",
    components: {
      faceForm,
      tab,
      VeLine,
      VeHistogram,
    },
    data: () => ({
      view: 'VeLine',
      tabs: [
        {label: '进客流', value: 'in'},
        {label: '出客流', value: 'out'},
        {label: '集客流', value: 'per'},
      ],
      chart: {
        title: '',
        query: {
          store: '',
          period: '',
          gender: '',
          age_type: '',
          start_date_time: '',
          end_date_time: '',
          stat_category: 'in',
        },
        data: {
          columns: ['时间'],
          rows: [],
        },
        dataZoom: [{
          type: 'slider',
          show: true
        }, {type: 'inside'}],
      },
    }),
    methods: {
      getFormParams(params) {
        Object.keys(params).forEach(key => this.chart.query[key] = params[key])
        this.getStatistics()
      },
      handleTabClick(data) {
        this.chart.query.stat_category = data
        this.getStatistics()
      },
      getStatistics() {
        Api.trend(this.chart.query).then(res => {
          if (res.data) {
            const {rows, xValue} = res.data,
                {label} = this.tabs.find(item => item.value === this.chart.query.stat_category)
            this.chart.title = `${xValue[0].includes('星期') ? this.$moment(xValue[0]).format('YYYY-MM') : xValue[0]} ${rows[0].name}门店趋势`
            this.chart.data.columns = ['时间', label]
            this.chart.data.rows = xValue.map((time, i) => Object.assign({['时间']: time.includes('星期') ? time.slice(-4, -1) : time}, ...rows.map(row => ({[label]: row.yList[i]}))))
          } else {
            this.chart.data.rows = []
          }
        }).catch(err => {
          console.log(err);
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .charts-wrap {
    .title {
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &::v-deep {
        .radio-group {
          margin: initial;
        }
      }
    }
  }
</style>